
import { Component, Vue } from 'vue-property-decorator';
import EngineerInfo from '@/components/User/EngineerInfo.vue';
import SnsApi from '@/module/api/SnsApi';

@Component({
  components: {
    EngineerInfo
  }
})
export default class UserProfile extends Vue {
  lineDisconnect():void {
    SnsApi.lineDisconnect().then(() => {
      this.$root.$emit('event-show-snackbar', 'LINE連携を解除しました');
    });
  }
}
